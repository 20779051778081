import React, { useState, useContext, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, TextField, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ProgressBarContext } from '../contexts/ProgressBarContext';
import { SurveyDataContext } from '../contexts/SurveyDataContext';

const FeedbackForm = () => {
    const { surveyData, setSurveyData } = useContext(SurveyDataContext);
    const { setProgress } = useContext(ProgressBarContext);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setProgress(99);
    }, [setProgress]);

    const handleCancelEnd = () => {
        navigate("/survey");
    };

    const handleFeedbackChange = (event) => {
        const { name, value } = event.target;
        setSurveyData({ ...surveyData, [name]: value });
    };

    const handleConfirmEnd = async () => {
        try {
            const filteredVignettes = surveyData.vignettes.map(vignette => ({
                id: vignette.id,
                selectedValue: vignette.selectedValue,
            }));
            const updatedData = {
                ...surveyData, // Access the latest survey data directly
                vignettes: filteredVignettes,
                endTime: new Date().toISOString(),
            };
            console.log('Survey data before submission:', updatedData);
    
            // Submit the data
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedData)
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            // Cleanup local storage
            localStorage.removeItem("sessionId");
            localStorage.removeItem("surveyData");
            localStorage.removeItem("surveyItems");
            localStorage.removeItem("currentItemIndex");
    
            // Navigate to the end page
            navigate("/end");
    
        } catch (error) {
            console.error('Error during submission:', error);
        }
    };

    return (
        <div className='contentDiv center'>
            <p sx={{ fontWeight: 'bold' }}>(Optional) <br/> Please share some insights into why you answered the way you did.</p>
            <TextField
                autoFocus
                margin="dense"
                name="comments0"
                label="In general, what was your reasoning behind the silder values you chose?"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={surveyData.comments0}
                onChange={handleFeedbackChange}
            />
            <TextField
                margin="dense"
                name="comments1"
                label="Is there anything you would change about the survey?"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={surveyData.comments1}
                onChange={handleFeedbackChange}
            />
            <TextField
                margin="dense"
                name="comments2"
                label="Additional Feedback"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={surveyData.comments2}
                onChange={handleFeedbackChange}
            />
            <Box className="buttonGroup">
                <Button onClick={handleCancelEnd} color="primary">Back</Button>
                <Button onClick={() => setOpen(true)} color="primary">Finish</Button>
            </Box>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>Are you sure you want to submit your responses?</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ color: 'gray' }}>
                        This will submit all of your responses and you will not be able to go back to change your answers.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="primary">Cancel</Button>
                    <Button onClick={handleConfirmEnd} color="primary">Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default FeedbackForm;

