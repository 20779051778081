import React, { useState, useContext, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Typography, Box, DialogContent, DialogContentText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SurveyItem from './SurveyItem';
import { ProgressBarContext } from '../contexts/ProgressBarContext';
import { SurveyDataContext } from '../contexts/SurveyDataContext';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

const SurveyContainer = () => {
  const { surveyData, setSurveyData } = useContext(SurveyDataContext);
  const [currentItemIndex, setCurrentItemIndex] = useState(() => parseInt(localStorage.getItem('currentItemIndex'), 10) || 0);
  const [selectedSliderValue, setSelectedSliderValue] = useState(1);
  const navigate = useNavigate();
  const { setProgress } = useContext(ProgressBarContext);
  const [introOpen, setIntroOpen] = useState(true);
  const [attentionCheckFailCount, setAttentionCheckFailCount] = useState(0);

  useEffect(() => {
    localStorage.setItem('currentItemIndex', currentItemIndex);
    updateProgress();
  }, [currentItemIndex]);

  useEffect(() => {
    if (surveyData.vignettes.length === 0) {
      loadSurveyData();
    }
  }, [surveyData.vignettes]);

  useEffect(() => {
    if (surveyData.vignettes.length > 0) {
      const savedValue = surveyData.vignettes[currentItemIndex]?.selectedValue;
      setSelectedSliderValue(savedValue !== undefined ? savedValue : 5);
    }
  }, [currentItemIndex, surveyData.vignettes]);

  const loadSurveyData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/survey-items`);
      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      setSurveyData(prevData => ({
        ...prevData,
        sessionId: data.sessionId,
        vignettes: data.selectedVignettes,
      }));
    } catch (error) {
      console.error('Error fetching survey items:', error);
    }
  };

  const updateProgress = () => {
    if (currentItemIndex === 0) {
      setProgress(25);
    } else {
      setProgress(25 + (currentItemIndex * (75 / surveyData.vignettes.length)));
    }
  };

  const handleSliderChange = (value) => {
    setSelectedSliderValue(value);
    setSurveyData(prevData => {
      const updatedVignettes = [...prevData.vignettes];
      updatedVignettes[currentItemIndex] = {
        ...updatedVignettes[currentItemIndex],
        selectedValue: value,
      };
      return {
        ...prevData,
        vignettes: updatedVignettes,
      };
    });
  };

  const handleSubmit = () => {
    const currentItem = surveyData.vignettes[currentItemIndex];

    if (currentItem.scenario === 'AttentionCheck' && selectedSliderValue !== 3) {
      setAttentionCheckFailCount(prevCount => prevCount + 1);
      if (attentionCheckFailCount + 1 >= 2) {
        setSurveyData(prevData => ({
          ...prevData,
          attentionCheckFailed: true,
        }));
      }
    }

    setSurveyData(prevData => ({
      ...prevData,
      vignettes: [
        ...prevData.vignettes.slice(0, currentItemIndex),
        {
          ...currentItem,
          selectedValue: selectedSliderValue,
        },
        ...prevData.vignettes.slice(currentItemIndex + 1),
      ]
    }));

    if (currentItemIndex < surveyData.vignettes.length - 1) {
      setCurrentItemIndex(currentItemIndex + 1);
    } else {
      navigate("/feedback");
    }
  };

  const goBack = () => {
    if (currentItemIndex > 0) {
      setCurrentItemIndex(currentItemIndex - 1);
    } else {
      navigate("/iuipc");
    }
  };

  if (surveyData.vignettes.length === 0) {
    return <Typography>Loading survey items...</Typography>;
  }

  const currentItem = surveyData.vignettes[currentItemIndex];

  return (
    <div className='contentDiv center'>
      <Tooltip title="Read Instructions again">
        <Button
          sx={{ mt:"0.5rem", width: "30vw" }}
          onClick={() => setIntroOpen(true)}
          color="primary"
          startIcon={<InfoIcon />}
        >
          Instructions
        </Button>
      </Tooltip>
      <Box sx={{
        padding: 4,
        borderRadius: 2,
        border: 2,
        margin: 2,
        width: "70vw",
        overflow: 'auto'
      }}>
        <Typography fontWeight={400} variant="h5">
          {currentItem.scenario === "ChatGPT"
            ? <p>Bob is researching his health condition with <b style={{ color: '#1976d2' }}>ChatGPT</b>. He types the following message to the chatbot:</p>
            : <p>Bob wants to book an appointment with a doctor through an <b style={{ color: '#1976d2' }}>online booking platform</b>. Before being able to see availabilities, he needs to describe his symptoms to a chatbot and writes the following message:</p>}
        </Typography>
        {/* Message Box */}

        <Typography variant="h5" sx={{ color: '#1976d2' }}>
          "{currentItem.content[selectedSliderValue - 1].text}"
        </Typography>
        <br/>
        <Typography variant="h5">
          Before receiving his answer, the chatbot requests Bob to share this conversation with
          <b style={{ color: '#1976d2' }}>
            {currentItem.scenario === "ChatGPT" ? " ChatGPT" : " the booking platform"}
            {currentItem.reason === "vague" ? " to train their chatbot further" :
              (currentItem.reason === "specific" ? " to improve their systems for securely storing and managing personal health information, ensuring enhanced privacy protection" :
                ""
              )
            }
          </b>.
        </Typography>
      </Box>
      <Box sx={{
        padding: 4,
        borderRadius: 2,
        border: 2,
        margin: 2,
        width: "70vw"
      }}>
        <Typography variant="h5" sx={{ margin: 3 }}>
          Given that you are in Bob’s position, please modify the message through the slider so that you are comfortable sharing this message for the described scenario.
        </Typography>
        <SurveyItem props={currentItem} sliderValue={selectedSliderValue} onSliderChange={handleSliderChange} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 3 }}>
          <Button variant="outlined" onClick={goBack}>Back</Button>
          <Button variant="outlined" onClick={handleSubmit}>Next</Button>
        </Box>
      </Box>
      <Dialog open={introOpen} onClose={() => setIntroOpen(false)}>
        <DialogTitle sx={{ fontWeight: 'bold' }}>Welcome to the vignettes. Please read below!</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'gray' }}>
            This survey consists of several <i>vignettes</i>. A vignette is a brief scenario in which we ask you to place yourself, and accordingly respond to the given question.
            The prompt as well as the <span style={{ color: '#1976d2' }}>blue response text</span> in the vignettes will change as you progress through the survey.
            Use the slider to adjust this text so that you feel comfortable sharing it under the given scenario.
            <br /><br />
            <b>NOTE:</b> In this study, we focus on how AI providers (e.g., OpenAI) <i>store</i> your personal data, which we refer to as <b>data sharing</b>.
            When choosing an option for each vignette, remember that the text you select will be <i>saved</i> or <i>captured</i> by the service provider. However, the original text (slider value 5) will be used for generating the answer you receive to your query, so this should not affect your response with respect to <b>data sharing</b>. The quality of response or service will not be affected by privatizing your data before sharing it.
            <br /><br />
            Please also note that for some options, the text may include typos or unfinished sentences. Please disregard these as this is sometimes a result of text generation methods.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIntroOpen(false)} color="primary">Got it!</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SurveyContainer;